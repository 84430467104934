import { Database } from "./Database";
import { Network } from "./Network";
import { Twitch } from "./Twitch";
import { PlayFab, InventoryResponse } from './PlayFab';

export class Account {
  protected static userId:string = '';
  protected static userName:string = '';
  protected static userPortrait:string = '';
  protected static loggedIn:boolean = false;
  protected static inventory:any[] = [];
  protected static inventoryLoaded:boolean = false;
  protected static callbacks:any = {};
  protected static platformUserId:string = '';
  protected static season:any = null;
  protected static seasonTime:number = 0;

  public static async loginWithCustomId(id:string) {
    let response = await PlayFab.post('LoginWithCustomID', {
      TitleId: 'D93AF', 
      CustomId: id, 
      CreateAccount: true
    });

    return {success: (response.status == 200), body: response.body};
  }

  public static async loginWithEmailAddress(email:string, password:string) {
    let response = await PlayFab.post('LoginWithEmailAddress', {
      TitleId: 'D93AF', 
      Email: email, 
      Password: password,
      RequireBothUsernameAndEmail: false
    });
    
    return {success: (response.status == 200), body: response.body};
  }

  public static async loginWithTwitch() {
    Twitch.login();
  }

  public static async continueLoginWithTwitch(accessToken:string) {
    let response = await PlayFab.loginWithTwitch(accessToken);

    if(response.success) {
      Twitch.setAccessToken(accessToken);
      let userResponse = await Twitch.getUser();
      if(userResponse.success) 
        await Account.finishLoginWithTwitch(accessToken, userResponse);
    }

    return {success: response.success};
  }

  public static async finishLoginWithTwitch(accessToken:string, userResponse:any) {
    Account.userId = PlayFab.getPlayerId();
    Account.userName = userResponse.body.data[0].display_name;
    Account.userPortrait = userResponse.body.data[0].profile_image_url;
    Account.platformUserId = userResponse.body.data[0].id;
    Account.loggedIn = true;

    let cache = {
      platform: 'twitch',
      user: userResponse.body.data[0].id,
      token: accessToken
    };

    localStorage.setItem('AutoLogin', JSON.stringify(cache));

    await Account.finishLogin();
  }

  protected static async finishLogin() {
    let response = await PlayFab.executeCloudScript('getSeason');
    if(response.success) {
      Account.season = response.data.season;
      Account.seasonTime = performance.now();
    }

    Account.makeCallbacks();
  }

  public static async getInventory(force: boolean = false) {
    if (!force && Account.inventoryLoaded)
      return Account.inventory;
  
    Account.inventory = [];
    Account.inventoryLoaded = true;
  
    let allItems: any[] = [];
    let continuationToken: string | null = null;
  
    do {
      const response: InventoryResponse = await PlayFab.getInventory(continuationToken);
  
      if (response.success && response.items) {
        allItems = allItems.concat(response.items);
        continuationToken = response.continuationToken || null;
      } else {
        console.error("Failed to fetch inventory");
        return Account.inventory;
      }
    } while (continuationToken);

  for (let i = 0; i < allItems.length; i++) {
    let serverId = allItems[i].Id;
    let amount = allItems[i].Amount;
    let asset = Database.getAssetByServerId(serverId);
    if (asset)
      Account.inventory.push({ id: asset.id, amount })
  }

  return Account.inventory;
}

  public static addAssetToInventoryCache(id:string, amount:number) {
    if(!Account.inventoryLoaded)
      return;

    let existing = Account.inventory.find((a:any)=>a.id == id);
    if(existing)
      existing.amount += amount;
    else
      Account.inventory.push({id, amount});
  }

  public static hasAutoLogin() {
    let item = localStorage.getItem('AutoLogin');
    return item != null;
  }

  public static async autoLogin() {
    let item = localStorage.getItem('AutoLogin');
    if(!item)
      return {success: false};

    let cache = JSON.parse(item);
    let accessToken = cache.token;

    if(cache.platform == 'twitch') {
      Twitch.setAccessToken(accessToken);
      let userResponse = await Twitch.getUser();
      if(userResponse.success) {
        let response = await PlayFab.loginWithTwitch(accessToken);
        if(response.success) {
          await Account.finishLoginWithTwitch(accessToken, userResponse);
          return response;
        }
      }
    }

    localStorage.removeItem('AutoLogin');

    return {success: false};
  }

  public static async logout() {
    Account.userId = '';
    Account.userName = '';
    Account.userPortrait = '';
    Account.platformUserId = '';
    Account.loggedIn = false;
    localStorage.removeItem('AutoLogin');
    Account.makeCallbacks();
  }

  public static async registerUser(email:string, password:string) {
    let response = await PlayFab.post('RegisterPlayFabUser', {
      TitleId: 'D93AF', 
      Email: email, 
      Password: password,
      RequireBothUsernameAndEmail: false
    });

    return {success: (response.status == 200), body: response.body};
  }

  public static isLoggedIn() {
    return Account.loggedIn;
  }

  public static getUserId() {
    return Account.userId;
  }

  public static getPlatformUserId() {
    return Account.platformUserId;
  }

  public static getUserName() {
    return Account.userName;
  }

  public static getUserPortrait() {
    return Account.userPortrait;
  }

  public static addCallback(id:string, callback:Function) {
    Account.callbacks[id] = callback;
  }

  public static removeCallback(id:string) {
    Account.callbacks[id] = null;
  }

  protected static makeCallbacks() {
    let ids = Object.keys(Account.callbacks);
    for(let i = 0; i < ids.length; i++) {
      let callback = Account.callbacks[ids[i]];
      if(callback)
        callback();
    }
  }

  public static async loadSeason() {
    let elapsed = performance.now() - Account.seasonTime;
    if(elapsed < (5 * 60 * 1000)) 
      return;

    let response = await PlayFab.executeCloudScript('getSeason');
    
    if(response.success) {
      Account.season = response.data.season;
      Account.seasonTime = performance.now();
    }
  }

  public static getSeason() {
    return Account.season;
  }

  public static getSeasonPlayerData() {
    if(!Account.season) return {level: 0, current: 0, max: 0};
    let xpPerLevel = Account.season.levels[0];
    let level = Math.floor(Account.season.xp / xpPerLevel);
    let current = Account.season.xp - (level * xpPerLevel);
    let max = xpPerLevel;
    return {level, current, max}
  }

  public static getSeasonQuests() {
    return Account.season ? Account.season.quests : [];
  }

  public static getSeasonMilestones() {
    return Account.season ? Account.season.milestones : [];
  }

  public static getSeasonRewards() {
    return Account.season ? Account.season.rewards : [];
  }

  public static async claimQuestReward(qi:number) {
    let response = await PlayFab.executeCloudScript('claimQuestReward', {quest: qi});
    if(response.success) {
      Account.season.xp = response.data.xp;
      Account.season.quests[qi].claimed = true;
      if(qi != 0)
        Account.season.quests[0].progress++;
    }
    return {success: response.success};
  }

  public static async claimMilestoneReward(mi:number) {
    let response = await PlayFab.executeCloudScript('claimMilestoneReward', {milestone: mi});
    if(response.success) {
      Account.season.xp = response.data.xp;
      Account.season.milestones[mi].level++;
      if(response.data.goal)
        Account.season.milestones[mi].goal = response.data.goal;
    }
    return {success: response.success};
  }

  public static async claimSeasonReward(level:number) {
    let response = await PlayFab.executeCloudScript('claimSeasonReward', {level});
    let success = response.success && response.data && response.data.success;
    if(success) 
      Account.season.rewards[level].claimed = true;
    return {success};
  }

  public static isDeveloperLoggedIn():boolean {
    let developers = [
      'cryptojack21', 
      'sammysnake7', 
      'sixbitglitch', 
      'turisstation', 
      'superdopetv', 
      'reverendtate', 
      'rob_boffin',
      'beanpole1904', 
      'gizmogorilla',
      // 'raks_wax',
      // 'dobermann_twitch'
    ];  

    return Account.isLoggedIn() && developers.indexOf(Account.getUserName().toLowerCase()) != -1;
  }
}