import React from 'react';
import { Database } from './services/Database';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill, BsArrowsExpandVertical, BsFillCaretLeftFill, BsFillCaretRightFill, BsFillXCircleFill, BsWechat } from 'react-icons/bs';
import { Network } from './services/Network';
import { Account } from './services/Account';
import './HostPage.css';

let LOADOUT_VERSION = 1

interface HostPageState {
  user: string;
  channel: string;
  mode: string;
  alert: string;
  loadout: any;
  collection: string;
  type: string;
  online: boolean;
  tag: string;
  showChat: boolean;
  inventory: any[]
}

class HostPage extends React.Component<{}, HostPageState> {
  protected monitorTimer:any;
  protected lastActionTime:number;

  constructor(props:{}) {
    super(props);
    this.state = {
      user: 'CryptoJack21',
      channel: '',
      mode: 'chat',
      alert: '',
      loadout: null,
      collection: '',
      type: '',
      online: false,
      tag: '',
      showChat: true,
      inventory: []
    }
    this.lastActionTime = 0;
  }

  componentDidMount(): void {
    if(Account.isLoggedIn())
      Network.connect();

    let parts = window.location.pathname.split('/');
    if(parts.length == 3)
      this.setState({channel: parts[2]});

    let loadout = this.loadLoadout();
    this.setState({loadout});

    this.startMonitoringNetwork();

    this.loadInventory();
  }

  componentWillUnmount(): void {
    this.stopMonitoringNetwork();
    Network.disconnect();
  }

  loadLoadout() {
    let loadout = {
      version: LOADOUT_VERSION,
      car: {
        id: Database.getRandomCar().id,
        booster: 'none',
        hood: 'none',
        door: 'none',
        emote: 'none',
        hat: 'none',
        horn: 'none',
        paint: 'none',
        ram:'none',
        trail: 'none',
        underglow: 'none'
      },
      driver: {
        id: Database.getRandomDriver().id,
        celebration: 'none',
        skin: 'none'
      },
      passenger: {
        id: Database.getRandomDriver().id,
        celebration: 'none',
        skin: 'none'
      }
    };

    let s = window.localStorage.getItem('Loadout');

    if(s) {
      let parsedLoadout = JSON.parse(s);
      if(parsedLoadout.version == LOADOUT_VERSION)
        loadout = parsedLoadout;
    }

    return loadout;
  }

  async loadInventory() {
    if(!Account.isLoggedIn())
      return;

    let inventory = await Account.getInventory();
    this.setState({inventory});
  }

  startMonitoringNetwork() {
    this.monitorTimer = setInterval(() => {
      let online = Network.isConnected();
      if(this.state.online != online)
        this.setState({online});
    }, 1000);
  }

  stopMonitoringNetwork() {
    clearInterval(this.monitorTimer);
  }

  sendActionEvent(action:string, data:any = null) {
    let event:any = {
      id: 'game-action', 
      channel: this.state.channel.toLowerCase(), 
      user: {
        id: Account.getPlatformUserId(),
        name: Account.getUserName()
      },
      action
    };

    if(data)
      event.data = data;

    Network.sendEvent(event);
  }

  saveLoadout() {
    let s = JSON.stringify(this.state.loadout);
    window.localStorage.setItem('Loadout', s);
    this.sendActionEvent('loadout', this.state.loadout);
  }

  onAction(action:string) {
    if(!Account.isLoggedIn()) {
      this.setState({alert: 'Please login to play.'});
      return;
    }

    if(!Network.isConnected()) {
      this.setState({alert: 'Not connected to the network!'});
      return;
    }

    // block spamming of horn and emote
    // let now = performance.now();
    // let elapsed = now - this.lastActionTime;
    // if(elapsed < 10000 && action == 'horn') 
    //   return;
    // if(elapsed < 5000 && action == 'emote') 
    //   return;
    // this.lastActionTime = now;

    this.sendActionEvent(action);
  }

  onJoinRace() {
    if(!Account.isLoggedIn()) {
      this.setState({alert: 'Please login to play.'});
      return;
    }

    if(!Network.isConnected()) {
      this.setState({alert: 'Not connected to the network!'});
      return;
    }

    this.sendActionEvent('race', this.state.loadout);
  }

  onCustomizeSave() {
    this.saveLoadout();
    this.setState({mode: 'chat'})
  }

  onCustomizeClose() {
    let loadout = this.loadLoadout();
    this.setState({loadout, mode: 'chat'});    
  }

  onCarClick(id:string) {
    let loadout = {...this.state.loadout};
    loadout.car.id = id;
    this.setState({loadout, mode: 'customize-car'});
  }

  onCosmetic(collection:string, tag:string, type:string) {
    this.setState({mode: 'cosmetics', type, tag, collection})
  }

  onCosmeticClick(asset:string, collection:string, tags:string[]) {
    let loadout = {...this.state.loadout};

    if(collection == 'boosters') loadout.car.booster = asset;
    if(collection == 'decals' && tags.indexOf('hood') != -1) loadout.car.hood = asset;
    if(collection == 'decals' && tags.indexOf('door') != -1) loadout.car.door = asset;
    if(collection == 'emotes') loadout.car.emote = asset;
    if(collection == 'hats') loadout.car.hat = asset;
    if(collection == 'horns') loadout.car.horn = asset;
    if(collection == 'paint') loadout.car.paint = asset;
    if(collection == 'rams') loadout.car.ram = asset;
    if(collection == 'trails') loadout.car.trail = asset;
    if(collection == 'underglows') loadout.car.underglow = asset;
    if(collection == 'skins') loadout[this.state.type].skin = asset;
    if(collection == 'celebrations') loadout[this.state.type].celebration = asset;

    this.setState({loadout, mode: this.state.type == 'car' ? 'customize-car' : 'customize-drivers'});
  }

  setMode(mode:string) {
    if(!Account.isLoggedIn()) {
      this.setState({alert: 'Please login to play.'});
      return;
    }

    if(!Network.isConnected()) {
      this.setState({alert: 'Not connected to the network!'});
      return;
    }

    this.setState({mode});
  }

  onNextCar() {
    let loadout = {...this.state.loadout};

    let ci = Database.getCarIndex(loadout.car.id);

    if(ci == -1) 
      loadout.car.id = Database.getFirstCar().id;
    else if(ci == Database.getTotalCars() - 1)
      loadout.car.id = 'random';
    else
      loadout.car.id = Database.getNextCar(loadout.car.id).id;

    this.setState({loadout});
  }

  onPreviousCar() {
    let loadout = {...this.state.loadout};

    let ci = Database.getCarIndex(loadout.car.id);

    if(ci == -1) 
      loadout.car.id = Database.getLastCar().id;
    else if(ci == 0)
      loadout.car.id = 'random';
    else
      loadout.car.id = Database.getPreviousCar(loadout.car.id).id;

    this.setState({loadout});
  }

  onNextDriver(type:string) {
    let loadout = {...this.state.loadout};

    let di = Database.getDriverIndex(loadout[type].id);

    if(di == -1) 
      loadout[type].id = Database.getFirstDriver().id;
    else if(di == Database.getTotalDrivers()-1)
      loadout[type].id = 'random';
    else
      loadout[type].id = Database.getNextDriver(loadout[type].id).id;

    this.setState({loadout});
  }

  onPreviousDriver(type:string) {
    let loadout = {...this.state.loadout};

    let di = Database.getDriverIndex(loadout[type].id);

    if(di == -1) 
      loadout[type].id = Database.getLastDriver().id;
    else if(di == 0)
      loadout[type].id = 'random';
    else
      loadout[type].id = Database.getPreviousDriver(loadout[type].id).id;

    this.setState({loadout});
  }

  onSelectDriver(type:string) {
    this.setState({type, mode: 'drivers'});
  }

  onDriverClick(id:string) {
    let loadout = {...this.state.loadout};
    loadout[this.state.type].id = id;
    this.setState({loadout, mode: 'customize-drivers'})
  }

  onSwap() {
    let loadout = {...this.state.loadout};
    let driver = loadout['driver'].id;
    loadout['driver'].id = loadout['passenger'].id;
    loadout['passenger'].id = driver;
    this.setState({loadout})
  }

  onToggleChat() {
    this.setState({showChat: !this.state.showChat});
  }

  renderVideo2() {
    return (
      <div className="host-page-video">
        <div style={{backgroundColor: 'green', width: '100%', height: '100%', position: 'absolute', top: '0'}}>
        </div>
      </div>
    )
  }

  renderVideo() {
    return (
      <div className="host-page-video">
        <iframe
          src={`https://player.twitch.tv/?channel=${this.state.channel.toLowerCase()}&parent=${window.location.hostname}&low_latency=true`}
          frameBorder={0}
          allowFullScreen={true} 
        >
        </iframe>
      </div>
    )
  }

  renderChat2() {
    let display = this.state.showChat ? 'block' : 'none';

    return (
      <div className="host-page-chat" style={{display, backgroundColor: 'orange', width: '100%'}}>
        Testing
        Testing 2
      </div>
    )
  }

  renderChat() {
    let display = this.state.showChat ? 'block' : 'none';

    return (
      <iframe
        id="TwitchChat"
        className="host-page-chat"
        src={`https://www.twitch.tv/embed/${this.state.channel.toLowerCase()}/chat?parent=${window.location.hostname}`}
        frameBorder={0}
        allowFullScreen={true} 
        style={{display}}
      >
      </iframe>
    )
  }

  renderCarsDialog() {
    let divs = [];
    for(let i = 0; i < Database.cars.length; i++) {
      let car = Database.cars[i];
      let src = `/images/cars/${car.id}.png`;
      divs.push(
        <div key={i} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onCarClick(car.id)}>
          <img style={{width: '100%'}} src={src} />
          <div style={{fontSize: '0.8em', textAlign: 'center'}}>{car.name}</div>
        </div>
      )
    }

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{padding: '0px', maxHeight: '525px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'customize-car'})}>
              <BsArrowLeftCircleFill />
            </div>
            <div style={{textAlign: 'center'}}>Select Car</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'chat'})}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="host-page-modal-content">
            {divs}
          </div>
        </div>
      </div>
    )
  }

  renderDriversDialog() {
    let divs = [];
    for(let i = 0; i < Database.drivers.length; i++) {
      let driver = Database.drivers[i];
      let src = `/images/drivers/${driver.id}.png`;
      divs.push(
        <div key={i} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onDriverClick(driver.id)}>
          <img style={{width: '100%', borderRadius: '3px'}} src={src} />
          <div style={{fontSize: '0.8em', textAlign: 'center'}}>{driver.nick ? driver.nick : driver.name}</div>
        </div>
      )
    }

    let title = 'Select Driver';
    if(this.state.mode == 'passengers')
      title = 'Select Passenger'

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{padding: '0px', maxHeight: '600px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'customize-drivers'})}>
              <BsArrowLeftCircleFill />
            </div>
            <div style={{textAlign: 'center'}}>{title}</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'chat'})}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="host-page-modal-content">
            {divs}
          </div>
        </div>
      </div>
    )
  }

  renderCosmeticsDialog() {
    let collection = Database.getCollection(this.state.collection)
    let assets = Database.getAssets(this.state.collection);

    let divs = [];

    divs.push(
      <div key={assets.length} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onCosmeticClick('none', this.state.collection, [this.state.tag])}>
        <img style={{width: '100%', borderRadius: '5px'}} src="/images/none.png" />
        <div style={{fontSize: '0.8em', textAlign: 'center'}}>None</div>
      </div>
    )

    for(let i = 0; i < assets.length; i++) {
      let asset = assets[i];
      if(this.state.tag && asset.tags.indexOf(this.state.tag) == -1)
        continue;
      let existing = this.state.inventory.find((a:any)=>a.id == asset.id);
      if(!existing && (this.state.channel != 'evolverstudios') && (this.state.channel != 'turisstation'))
        continue;
      let src = `/images/assets/${this.state.collection}/${asset.image ? asset.image : asset.id}.png`;
      divs.push(
        <div key={i} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onCosmeticClick(asset.id, asset.collection, asset.tags)}>
          <img style={{width: '100%', borderRadius: '5px'}} src={src} />
          <div style={{fontSize: '0.8em', textAlign: 'center'}}>{asset.name}</div>
        </div>
      )
    }

    let title = collection.name;
    
    if(collection.name.substring(collection.name.length-1) == 's')
      title = collection.name.substring(0, collection.name.length-1);

    let backMode = this.state.type == 'car' ? 'customize-car' : 'customize-drivers';

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{padding: '0px', height: '525px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: backMode})}>
              <BsArrowLeftCircleFill />
            </div>
            <div style={{textAlign: 'center'}}>Select {title}</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'chat'})}>
              <BsFillXCircleFill />
            </div>
          </div>
            <div className="host-page-modal-content">
              {divs}
            </div>
        </div>
      </div>
    )
  }

  renderStatBar(key:number, label:string, value:number) {
    let pips = [];
    for(let i = 0; i < 5; i++) {
      let color = i < value ? 'orange' : '#ffffff10';
      pips.push(
        <div key={i} style={{height: '10px', width: '100%', borderRadius: '5px', backgroundColor: color}} />
      )
    }

    let barWidth = `${value * 20}%`;
    let background = 'linear-gradient(197.78deg, #F1BD42 -12.54%, #EF8F49 104.72%)';
    return (
      <div key={key}>
        <div style={{color: 'gray'}}>{label}</div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', columnGap: '5px', marginTop: '3px'}}>
          {pips}
        </div>

        {/* <div style={{backgroundColor: 'black', width: '100%', height: '10px', borderRadius: '5px', marginTop: '2px', position: 'relative'}}>
          <div style={{background, width: barWidth, height: '100%', borderRadius: '5px', position: 'absolute', top: '0', left: '0'}} />
        </div> */}
      </div>
    )    
  }

  renderDriverStatBar(key:number, label:string, driver:number, passenger:number) {
    let pips = [];
    let total = 0;
    for(let i = 0; i < 8; i++) {
      let color = i < driver + passenger ? total < driver ? 'orange' : 'green' : '#ffffff10';
      total++;
      pips.push(
        <div key={i} style={{height: '15px', width: '100%', borderRadius: '5px', backgroundColor: color}} />
      )
    }

    return (
      <div key={key} style={{display: 'grid', gridTemplateColumns: '80px 1fr', alignItems: 'center'}}>
        <div style={{fontSize: '0.9em', color: 'gray', textAlign: 'left'}}>{label}</div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr', columnGap: '3px'}}>
          {pips}
        </div>
      </div>
    )    
  }

  renderAnalysisStatBar(key:number, label:string, values:number[]) {
    let pips = [];
    let total = 0;
    for(let i = 0; i < 8; i++) {
      let color = '';
      if(values[i] == 0)
        color = '#ffffff10';
      else if(values[i] <= 62)
        color = 'green';
      else if(values[i] <= 93)
        color = 'orange';
      else
        color = 'red';
      // else if(values > 20 && values < 50)
      //   color = 'yellow';
      // else if(values > 50 && values < 50)
      //   color = 'yellow';
      // else if(values > 20 && values < 50)
      //   color = 'yellow';
      total++;
      pips.push(
        <div key={i} style={{fontSize: '0.7em', color: 'black', height: '15px', width: '100%', borderRadius: '5px', backgroundColor: color}}>
          {/* {((values[i]/441)*100).toFixed(1)} */}
          {/* {((values[i]/441)*100).toFixed(0)}% */}
          {values[i]}
        </div>
      )
    }

    return (
      <div key={key} style={{display: 'grid', gridTemplateColumns: '80px 1fr', alignItems: 'center'}}>
        <div style={{fontSize: '0.9em', color: 'gray', textAlign: 'left'}}>{label}</div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr', columnGap: '3px'}}>
          {pips}
        </div>
      </div>
    )    
  }

  renderCarPanel() {
    let car = null;
    let carIdx = Database.getTotalCars();
    let carImage = `/images/random-car.png`;
    let carName = 'Random';

    if(this.state.loadout.car.id != 'random') {
      car = Database.getCar(this.state.loadout.car.id);
      carIdx = Database.getCarIndex(this.state.loadout.car.id);
      carImage = `/images/cars/${car.id}.png`;
      carName = car.name;
    }
    
    return (
      <div className="flex-row" style={{backgroundColor: 'var(--background-color)', padding: '10px', borderRadius: '5px'}}>
        <div style={{display: 'grid', gridTemplateColumns: '150px 1fr', columnGap: '15px', width: '100%'}}>
          <div>
            <img style={{width: '100%', borderRadius: '5px', cursor: 'pointer'}} src={carImage} onClick={()=>this.setMode('cars')} />
            <div className="flex-row" style={{width: '100%', justifyContent: 'space-between', color: 'gray', alignItems: 'center'}}>
              <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onPreviousCar()} >
                <BsArrowLeftCircleFill />
              </div>
              <div style={{color: 'white'}}>{carIdx+1}/{Database.getTotalCars()+1}</div>
              <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onNextCar()} >
                <BsArrowRightCircleFill />
              </div>
            </div>
          </div>
          <div className="flex-column" style={{rowGap: '0px', textAlign: 'left'}}>
            <div>{carName}</div>
            <div className="flex-column" style={{color: 'gray', fontSize: '0.8em', rowGap: '5px', marginTop: '5px'}}>
              {this.renderStatBar(0, 'Weight', car ? car.weight : 0)}
              {this.renderStatBar(1, 'Health', car ? car.health : 0)}
              {this.renderStatBar(2, 'Speed', car ? car.speed : 0)}
              {this.renderStatBar(3, 'Accel', car ? car.acceleration : 0)}
              {this.renderStatBar(4, 'Handling', car ? car.handling : 0)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCosmeticPanel(key:number, cosmetic:any, type:string) {
    let cosDiv = <div>{cosmetic.name}</div>
    if(cosmetic.asset != 'none') {
      let asset = Database.getAsset(cosmetic.asset);
      let src = `/images/assets/${asset.collection}/${asset.image ? asset.image : asset.id}.png`;
      cosDiv = <img style={{width: '100%', height: '100%', borderRadius: '5px'}} src={src} />;
    }

    return (
      <div key={key} className="host-page-car-cosmetic" onClick={()=>this.onCosmetic(cosmetic.collection, cosmetic.tag, type)}>
        {cosDiv}
      </div>
    )
  }

  renderCarCosmeticsPanel() {
    let loadout = this.state.loadout;

    let cosmetics = [
      {collection: 'paint', name: 'Paint', asset: loadout.car.paint},
      {collection: 'decals', name: 'Hood', tag: 'hood', asset: loadout.car.hood},
      {collection: 'decals', name: 'Door', tag: 'door', asset: loadout.car.door},
      {collection: 'trails', name: 'Trail', asset: loadout.car.trail},
      {collection: 'underglows', name: 'Glow', asset: loadout.car.underglow},
      {collection: 'emotes', name: 'Emote', asset: loadout.car.emote},
      {collection: 'boosters', name: 'Boost', asset: loadout.car.booster},
      {collection: 'rams', name: 'Ram', asset: loadout.car.ram},
      {collection: 'horns', name: 'Horn', asset: loadout.car.horn},
      {collection: 'hats', name: 'Hat', asset: loadout.car.hat}
    ]

    let divs = [];
    for(let i = 0; i < cosmetics.length; i++) 
      divs.push(this.renderCosmeticPanel(i, cosmetics[i], 'car'))
    
    return (
      <div className="flex-row" style={{backgroundColor: 'var(--background-color)', padding: '10px', borderRadius: '5px'}}>
        <div className="flex-row" style={{flexWrap: 'wrap', rowGap: '5px', columnGap: '5px', width: '100%'}}>
          {divs}
        </div>
      </div>
    )
  }

  renderCustomizeCarDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{maxWidth: '400px', padding: '0px', height: '525px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}}>
              &nbsp;
            </div>
            <div style={{textAlign: 'center'}}>Customize Car</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.onCustomizeClose()}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="flex-column" style={{rowGap: '10px', margin: '10px'}}>
            {this.renderCarPanel()}
            {this.renderCarCosmeticsPanel()}
            <div><button style={{width: '120px', margin: '5px 0px 5px 0px'}} onClick={()=>this.onCustomizeSave()}>Save</button></div>
          </div>
        </div>
      </div>
    );
  }

  renderDriverPanel(type:string) {
    let driverId = this.state.loadout[type].id;

    let driver = null;
    let driverIdx = Database.getTotalDrivers();
    let driverImage = `/images/random-driver.png`;
    let driverName = 'Random';

    if(driverId != 'random') {
      driver = Database.getDriver(driverId);
      driverIdx = Database.getDriverIndex(driverId);
      driverImage = `/images/drivers/${driverId}.png`;
      driverName = driver.name;
    }

    let celebration = 'None';
    if(this.state.loadout[type].celebration != 'none') {
      let asset = Database.getAsset(this.state.loadout[type].celebration);
      celebration = asset.name;
    }

    return (
      <div className="flex-column" style={{rowGap: '5px'}}>
        <img style={{width: '100%', borderRadius: '5px', cursor: 'pointer'}} src={driverImage} onClick={()=>this.onSelectDriver(type)} />
        <div>{driverName}</div>
        <div className="flex-row" style={{margin: 'auto', width: '85%', justifyContent: 'space-between', color: 'gray', alignItems: 'center'}}>
          <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onPreviousDriver(type)} >
            <BsArrowLeftCircleFill />
          </div>
          <div style={{fontSize: '0.9em'}}>{driverIdx+1}/{Database.getTotalDrivers()+1}</div>
          <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onNextDriver(type)} >
            <BsArrowRightCircleFill />
          </div>
        </div>
        <div className="host-page-celebration-button" onClick={()=>this.onCosmetic('celebrations', '', type)}>
          <div className="subtext" style={{fontSize: '0.8em'}}>Celebration</div>
          <div>{celebration}</div>
        </div>
      </div>
    )
  }

  renderCustomizeDriversDialog() {
    let driverId = this.state.loadout['driver'].id;
    let driver = Database.getDriver(driverId);

    let passengerId = this.state.loadout['passenger'].id;
    let passenger = Database.getDriver(passengerId);

    let statDivs = [];

    statDivs.push(this.renderDriverStatBar(5, 'Actions', driver ? Math.ceil(driver.actions * 0.5) : 0, passenger ? passenger.actions : 0));
    statDivs.push(this.renderDriverStatBar(1, 'Boosting', driver ? driver.boosting : 0, passenger ? Math.ceil(passenger.boosting * 0.5) : 0));
    statDivs.push(this.renderDriverStatBar(0, 'Driving', driver ? driver.driving : 0, passenger ? Math.ceil(passenger.driving * 0.5) : 0));
    statDivs.push(this.renderDriverStatBar(2, 'Morale', driver ? Math.ceil(driver.morale * 0.5) : 0, passenger ? passenger.morale : 0));
    statDivs.push(this.renderDriverStatBar(3, 'Ramming', driver ? driver.ramming : 0, passenger ? Math.ceil(passenger.ramming * 0.5) : 0));
    statDivs.push(this.renderDriverStatBar(4, 'Shielding', driver ? Math.ceil(driver.shielding * 0.5) : 0, passenger ? passenger.shielding : 0));

    // let actions = [0, 0, 0, 0, 0, 0, 0, 0];
    // let boosting = [0, 0, 0, 0, 0, 0, 0, 0];
    // let driving = [0, 0, 0, 0, 0, 0, 0, 0];
    // let morale = [0, 0, 0, 0, 0, 0, 0, 0];
    // let ramming = [0, 0, 0, 0, 0, 0, 0, 0];
    // let shielding = [0, 0, 0, 0, 0, 0, 0, 0];

    // for(let i = 0; i < Database.drivers.length; i++) {
    //   let driver = Database.drivers[i];

    //   for(let j = 0; j < Database.drivers.length; j++) {
    //     if(j == i)
    //       continue;

    //     let passenger = Database.drivers[j];

    //     let factor = 0.75;
    //     let a = Math.ceil(driver.actions * factor) + passenger.actions;
    //     let m = Math.ceil(driver.morale * factor) + passenger.morale;
    //     let s = Math.ceil(driver.shielding * factor) + passenger.shielding;
    //     let b = driver.boosting + Math.ceil(passenger.boosting * factor);
    //     let d = driver.driving + Math.ceil(passenger.driving * factor);
    //     let r = driver.ramming + Math.ceil(passenger.ramming * factor);

    //     actions[a-1]++;
    //     boosting[b-1]++;
    //     driving[d-1]++;
    //     morale[m-1]++;
    //     ramming[r-1]++;
    //     shielding[s-1]++;
    //   }
    // }

    // statDivs.push(this.renderAnalysisStatBar(5, 'Actions', actions));
    // statDivs.push(this.renderAnalysisStatBar(1, 'Boosting', boosting));
    // statDivs.push(this.renderAnalysisStatBar(0, 'Driving', driving));
    // statDivs.push(this.renderAnalysisStatBar(2, 'Morale', morale));
    // statDivs.push(this.renderAnalysisStatBar(3, 'Ramming', ramming));
    // statDivs.push(this.renderAnalysisStatBar(4, 'Shielding', shielding));

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{maxWidth: '400px', padding: '0px', minHeight: '600px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}}>
              &nbsp;
            </div>
            <div style={{textAlign: 'center'}}>Customize Driver & Passenger</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.onCustomizeClose()}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="flex-column" style={{margin: '10px'}}>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 20px 1fr', columnGap: '10px', backgroundColor: 'var(--background-color)', padding: '10px', borderRadius: '5px'}}>
              {this.renderDriverPanel('driver')}
              <div className="flex-column" style={{transform: 'translateY(35%)', cursor: 'pointer'}} onClick={()=>this.onSwap()}>
                <BsArrowsExpandVertical style={{fontSize: '1.0em'}} />
              </div>
              {this.renderDriverPanel('passenger')}
            </div>
            <div className="flex-column" style={{backgroundColor: 'var(--background-color)', padding: '10px', borderRadius: '5px'}}>
              {statDivs}
            </div>
            <div><button style={{width: '120px'}} onClick={()=>this.onCustomizeSave()}>Save</button></div>
          </div>
        </div>
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="host-page-buttons">
        <div className="flex-row">
          <div className="host-page-action-button" onClick={()=>this.onAction('horn')}><div style={{transform: 'translateY(-2px)'}}>🎺</div></div>
          <button onClick={()=>this.onJoinRace()}>Join</button>
          <button onClick={()=>this.setMode('customize-car')}>Car</button>
          <button onClick={()=>this.setMode('customize-drivers')}>Drivers</button>
          <div className="host-page-action-button" onClick={()=>this.onAction('emote')}><div style={{transform: 'translateY(-2px)'}}>😐</div></div>
        </div>
        <div className="flex-row">
          <button style={{width: '75px'}} onClick={()=>this.onAction('boost')}>🚀<br/>Boost</button>
          <button style={{width: '75px'}} onClick={()=>this.onAction('ram')}>🔰<br/>Ram</button>
          <button style={{width: '75px'}} onClick={()=>this.onAction('brake')}>🛑<br/>Brake</button>
          <button style={{width: '75px'}} onClick={()=>this.onAction('shield')}>🛡️<br/>Shield</button>
        </div>
        {/* <div className="host-page-network" style={{backgroundColor: this.state.online ? 'green' : 'red'}} /> */}
        <div className="host-page-chat-button" onClick={()=>this.onToggleChat()}>
          {this.state.showChat ? 
            <BsFillCaretRightFill style={{transform: 'translateY(-3px)'}} /> : 
            <BsFillCaretLeftFill style={{transform: 'translateY(-3px)'}} /> 
          }
          <BsWechat style={{fontSize: '1.5em'}} />
        </div>
      </div>
    )
  }

  renderMobile() {
    return (
      <div className="page">
        <div className="flex-column">
          {this.renderVideo()}
          {this.renderButtons()}
          {this.renderChat()}
        </div>
      </div>
    )
  }

  renderAlertDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '250px'}}>
          <div>{this.state.alert}</div>
          <div>
            <button style={{marginTop: '25px'}} onClick={()=>this.setState({alert: ''})}>OK</button>
          </div>
        </div>
      </div>
    )    
  }

  render() {
    if(this.state.channel == '')
        return null;

    return (
      <div className="page">
        <div className="host-page-container">
          {this.renderVideo()}
          {this.renderChat()}
          {this.renderButtons()}
          {this.state.mode == 'customize-car' && this.renderCustomizeCarDialog()}
          {this.state.mode == 'customize-drivers' && this.renderCustomizeDriversDialog()}
          {this.state.mode == 'cars' && this.renderCarsDialog()}
          {this.state.mode == 'drivers' && this.renderDriversDialog()}
          {this.state.mode == 'passengers' && this.renderDriversDialog()}
          {this.state.mode == 'cosmetics' && this.renderCosmeticsDialog()}
          {this.state.alert != '' && this.renderAlertDialog()}
        </div>
      </div>
    )
  }
}

export default HostPage;
