// Define the InventoryResponse interface
export interface InventoryResponse {
  success: boolean;
  items?: any[];
  continuationToken?: string | null;
}

export class PlayFab {
  protected static titleId = '7A13D';
  protected static playerId = '';
  protected static sessionTicket = '';
  protected static entityToken = '';
  protected static entity: any = null;

  public static post(method: string, body: any): Promise<{ status: number, body?: any }> {
    return new Promise((resolve, reject) => {
      var request = new XMLHttpRequest();
      request.open('POST', `https://7A13D.playfabapi.com/${method}`);
      request.setRequestHeader('Content-Type', 'application/json');

      if (PlayFab.sessionTicket != '')
        request.setRequestHeader('X-Authorization', PlayFab.sessionTicket);

      if (PlayFab.entityToken != '')
        request.setRequestHeader('X-EntityToken', PlayFab.entityToken);
      
      console.log(`> PLAYFAB /${method}`, body);
      
      let payload = JSON.stringify(body);
      request.send(payload);
  
      request.onload = () => {
        let responseBody = null;
        if (request.responseText != '')
          responseBody = JSON.parse(request.responseText);
  
        console.log('< PLAYFAB', responseBody);
  
        resolve({
          status: request.status,
          body: responseBody
        });
      };
  
      request.onerror = (e) => {
        console.log('< ERROR');
        resolve({
          status: 500,
          body: {message: 'Internal service error!'}
        });
      };
    });
  }

  public static async loginWithTwitch(accessToken: string): Promise<{ success: boolean }> {
    let response = await PlayFab.post('Client/LoginWithTwitch', {
      TitleId: PlayFab.titleId, 
      AccessToken: accessToken,
      CreateAccount: true
    });

    if (response.status == 200) {
      PlayFab.playerId = response.body.data.PlayFabId;
      PlayFab.sessionTicket = response.body.data.SessionTicket;
      PlayFab.entity = response.body.data.EntityToken.Entity;
      PlayFab.entityToken = response.body.data.EntityToken.EntityToken;
    }

    return { success: response.status == 200 };
  }

  public static async getInventory(continuationToken: string | null = null): Promise<InventoryResponse> {
    let body: any = {};
    if (continuationToken) {
      body.ContinuationToken = continuationToken;
    }

    let response = await PlayFab.post('Inventory/GetInventoryItems', body);

    if (response.status != 200) {
      return { success: false };
    }

    return {
      success: true,
      items: response.body.data.Items,
      continuationToken: response.body.data.ContinuationToken
    };
  }

  public static async getUserReadOnlyData(keys: string[]): Promise<{ success: boolean, data?: any }> {
    let response = await PlayFab.post('Client/GetUserReadOnlyData', { Keys: keys });

    if (response.status != 200)
      return { success: false };

    return { success: true, data: response.body.data.Data };
  }

  public static async executeCloudScript(func: string, params: any = {}): Promise<{ success: boolean, data?: any }> {
    let response = await PlayFab.post('Client/ExecuteCloudScript', { FunctionName: func, FunctionParameter: params, GeneratePlayStreamEvent: true });

    if (response.status != 200)
      return { success: false };

    return { success: true, data: response.body.data.FunctionResult };
  }

  public static getPlayerId(): string {
    return PlayFab.playerId;
  }
}