import React from 'react';
import {NavLink, Navigate, Outlet} from 'react-router-dom';
import { Account } from './services/Account';
import { BsArchiveFill, BsBroadcast, BsBookFill, BsCarFrontFill, BsEnvelope, BsFacebook, BsFillHouseFill, BsFillPlayCircleFill, BsFillTrophyFill, BsFilterCircleFill, BsGoogle, BsPersonCircle, BsTools, BsTwitch } from 'react-icons/bs';
import './MainPage.css';
import { Network } from './services/Network';

const menu = [
  {label: 'Home', link: '/', icon: <BsFillHouseFill style={{transform: 'translateY(2px)'}} />},
  {label: 'Play', link: '/play', icon: <BsFillPlayCircleFill style={{transform: 'translateY(2px)'}} />},
  {label: 'Codex', link: '/codex', icon: <BsBookFill style={{transform: 'translateY(2px)'}} />},
  {label: 'Season', link: '/season', icon: <BsFillTrophyFill style={{transform: 'translateY(2px)'}} />, developer: true},
  {label: 'Races', link: '/races', icon: <BsCarFrontFill style={{transform: 'translateY(2px)'}} />},
  {label: 'Garage', link: '/garage', icon: <BsArchiveFill style={{transform: 'translateY(2px)'}} />, developer: true},
  {label: 'Stats', link: '/stats', icon: <BsFillTrophyFill style={{transform: 'translateY(2px)'}} />, requireLogin: true},
  {label: 'Store', link: '/store', icon: <BsArchiveFill style={{transform: 'translateY(2px)'}} />, developer: true},
  {label: 'Tools', link: '/streamer', icon: <BsTools style={{transform: 'translateY(2px)'}} />, developer: true}
];

interface MainPageState {
  login: boolean;
  profile: boolean;
  menu: boolean;
  message: string;
  authenticating: boolean;
  navigate: string;
  returnUrl: string;
}

class MainPage extends React.Component<{}, MainPageState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      login: false,
      profile: false,
      menu: false,
      message: '',
      authenticating: true,
      navigate: '',
      returnUrl: '/',
    }
  }

  componentDidMount(): void {
    this.autoLogin();
  }

  async autoLogin() {
    if(Account.hasAutoLogin()) {
      this.setState({authenticating: true});
      setTimeout(async () => {
        let response = await Account.autoLogin();
        this.setState({authenticating: false});
      }, 500);
    }
    else
      this.setState({authenticating: false})
  }

  onLogin() {
    this.setState({
      login: true,
      returnUrl: window.location.pathname + window.location.search
    });
  }

  onLoginWithTwitch() {
    this.setState({message: 'Logging in...', login: false});
    // Store the current pathname in sessionStorage
    sessionStorage.setItem('loginReturnUrl', window.location.pathname);
    setTimeout(() => {
      Account.loginWithTwitch();
    }, 500);
  }

  onLogout() {
    this.setState({message: 'Logging out...', profile: false});
    setTimeout(() => {
      Network.disconnect();
      Account.logout();
      this.setState({message: ''})
    }, 500);
  }

  onProfile() {
    this.setState({profile: true});
  }

  onMenu() {
    this.setState({menu: true});
  }

  renderHeaderMenu(name:string, path:string) {
    return (
      <NavLink to={path} className="main-page-header-link">
        {name}
      </NavLink>
    )    
  }

  renderHeader() {
    let links = [];
    for(let i = 0; i < menu.length; i++) {
      if(menu[i].developer && !Account.isDeveloperLoggedIn()) 
        continue;
      if(menu[i].requireLogin && !Account.isLoggedIn())
        continue;

      links.push(
        <NavLink key={i} to={menu[i].link} className="main-page-header-link">
          {menu[i].label}
        </NavLink>
      )
    }

    return (
      <div className="main-page-header">
        <div className="main-page-header-grid">
          <div className="main-page-header-menu" onClick={()=>this.onMenu()}>
            <BsFilterCircleFill />
          </div>

          <div className="main-page-header-logo">
            <img style={{height: '36px', transform: 'translate(-4px, 3px)'}} src="/images/logo.webp"/>
          </div>

          <div className="main-page-header-links">
            {links}
          </div>

          {this.state.authenticating &&
            <div className="main-page-header-authenticating-button">
              {/* <BsDiscFill /> */}
              <BsBroadcast/>
            </div>
          }
          {!this.state.authenticating && Account.isLoggedIn() &&
            <div className="main-page-header-portrait" onClick={()=>this.onProfile()}>
              <img style={{width: '100%', borderRadius: '50%'}} src={Account.getUserPortrait()} />
            </div>
          }
          {!this.state.authenticating && !Account.isLoggedIn() &&
            <div className="main-page-header-login-button" onClick={()=>this.onLogin()}>
              <BsPersonCircle />
            </div>
          }
          {!this.state.authenticating && !Account.isLoggedIn() &&
            <div className="main-page-header-login" onClick={()=>this.onLogin()}>
              Log In
            </div>
          }
        </div>
      </div>
    )
  }

  renderLoginDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '250px'}}>
          <div>
            <img style={{height: '36px'}} src="/images/logo.webp"/>
          </div>
          <div className="flex-column" style={{marginTop: '10px'}}>
            <button onClick={()=>this.onLoginWithTwitch()}><BsTwitch style={{transform: 'translateY(5px)'}} />&nbsp;&nbsp;Login with Twitch</button>
            <button disabled={true}><BsGoogle style={{transform: 'translateY(3px)'}} />&nbsp;&nbsp;Login with Google</button>
            <button disabled={true}><BsFacebook style={{transform: 'translateY(3px)'}} />&nbsp;&nbsp;Login with Facebook</button>
            <button disabled={true}><BsEnvelope style={{transform: 'translateY(4px)'}} />&nbsp;&nbsp;Login with Email</button>
            <button style={{background: '#213e70'}} onClick={()=>this.setState({login: false})}>Close</button>
          </div>
        </div>
      </div>
    )
  }

  renderProfileDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '250px'}}>
          <div className="flex-column">
            <img style={{borderRadius: '50%', width: '120px', margin: 'auto'}} src={Account.getUserPortrait()} />
            <div>{Account.getUserName()}</div>
            <div className="flex-column" style={{marginTop: '10px'}}>
              <button>Account</button>
              <button>Settings</button>
              <button onClick={()=>this.onLogout()}>Logout</button>
              <button style={{background: '#213e70'}} onClick={()=>this.setState({profile: false})}>Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderMessageDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{maxWidth: '250px', padding: '20px'}}>
          {this.state.message}
        </div>
      </div>
    )
  }

  renderMenuDialog() {
    let buttons = [];
    for(let i = 0; i < menu.length; i++) {
      if(menu[i].developer && !Account.isDeveloperLoggedIn()) 
        continue;
      
      buttons.push(
        <NavLink key={i} to={menu[i].link}>
          <button style={{width: '100%'}} onClick={()=>this.setState({menu: false})}>
            {menu[i].icon}&nbsp;&nbsp;{menu[i].label}
          </button>
        </NavLink>
      )
    }

    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '220px'}}>
          <div>
            <img style={{height: '36px'}} src="/images/logo.webp"/>
          </div>
          <div className="flex-column" style={{marginTop: '10px'}}>
            {buttons}
            <button style={{background: '#213e70'}} onClick={()=>this.setState({menu: false})}>Close</button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if(this.state.navigate != '')
      return <Navigate to={this.state.navigate} />
      
    return (
      <div>
        {this.renderHeader()}
        {!this.state.authenticating &&
          <Outlet />
        }
        {this.state.login && this.renderLoginDialog()}
        {this.state.profile && this.renderProfileDialog()}
        {this.state.menu && this.renderMenuDialog()}
        {this.state.message != '' && this.renderMessageDialog()}
      </div>
    );
  }
}

export default MainPage;