// const SOCKET_URL = 'wss://i612nrbhp4.execute-api.us-west-2.amazonaws.com/dev/';
const SOCKET_URL = 'wss://8kh9tm8lfc.execute-api.eu-north-1.amazonaws.com/production/';

export class Network {
  protected static socket: WebSocket;
  protected static connected: boolean = false;
  protected static listeners: any[] = [];
  protected static pinging: boolean = false;
  protected static pingTimer: any = null;
  protected static lastPingTime: number = 0;

  public static addEventListener(callback:Function) {
    Network.listeners.push(callback);
  }

  public static removeEventListener(callback:Function) {
    for(let i = 0; i < Network.listeners.length; i++) {
      if(Network.listeners[i].callback == callback) {
        Network.listeners.splice(i, 1);
        return;
      }
    }
  }

  public static async connect() {
    if(Network.connected)
      return;

    return new Promise((resolve, reject) => {
      Network.socket = new WebSocket(SOCKET_URL);   // '?Authorization=accessToken'

      Network.socket.onopen = (event)=>{
        console.log('# SOCKET CONNECTED');
        Network.connected = true;
        Network.startPinging();
        resolve({success: true});
      };
  
      Network.socket.onerror = (event)=> {
        console.warn('# SOCKET ERROR');
        console.log(event);
        Network.connected = false;
        resolve({success: false});
      };
  
      Network.socket.onclose = (event)=>{
        console.log('# SOCKET DISCONNECTED');
        Network.connected = false;
      };
  
      Network.socket.onmessage = (message:any)=>Network.onMessage(message);
    });
  }

  public static disconnect() {
    Network.stopPinging();

    if(Network.socket) {
      Network.socket.close();
      Network.connected = false;
    }
  }

  public static isConnected(): boolean {
    return Network.connected;
  }

  protected static onMessage(message:any) {
    let data = JSON.parse(message.data);
    console.log('<--', data);
    for(let i = 0; i < Network.listeners.length; i++) 
      if(Network.listeners[i].callback) 
        Network.listeners[i].callback(data);
  }

  public static async sendEvent(event:any) {
    if(!Network.socket)
      return;

    let body = JSON.stringify(event);

    let logEvent = JSON.parse(JSON.stringify(event));
    delete logEvent.id;
    let keys = Object.keys(logEvent);
    if(keys.length == 0)
      logEvent = null;

    if(logEvent)
      console.log('> SOCKET', event.id, logEvent);
    else
      console.log('> SOCKET', event.id);

    try {
      Network.socket.send(body);
    }
    catch(e) {
      console.error('Failed to send command!');
      console.log(e);
    }
  }

  protected static startPinging() {
    if(Network.pinging) return;
    Network.pinging = true;

    Network.pingTimer = setInterval(() => {
      let now = performance.now();
      if(Network.lastPingTime == 0)
        Network.lastPingTime = now;
      let elapsed = now - Network.lastPingTime;

      // console.log(elapsed, document.hasFocus());
  
      if(document.hasFocus()) {{
        if(Network.isConnected()) {
          if(elapsed > 60000) {
            Network.sendEvent({id: 'keepalive'})
            Network.lastPingTime = now;
          }
        }
        else {
          console.log('# SOCKET RECONNECTING...');
          Network.connect();
        }
      }}
    }, 1000);
  }

  protected static stopPinging() {
    clearInterval(Network.pingTimer);
    Network.pinging = false;
  }
}

